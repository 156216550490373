import React from 'react'

export const Footer = () => {
  return (
    <div className='footer text-center'>
      <div className='text-center' style={{ marginTop: '2rem' }}>
          <span className='muted'>
            &copy; {new Date().getFullYear()} Alberta Spine Foundation
          </span>
        </div>
    </div>
  )
}
